import service from '@/services/category-integration/CategoryIntegrationService'

export const categoryIntegrationModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    categoriesIntegrationList: [],
    noMoreCategories: false,
  },
  mutations: {
    SET_CATEGORIES_INTEGRATION_LIST(state, payload) {
      if (payload.forward) {
        const newCategory = payload.data
        const uniqueCategories = newCategory.filter((newCategoryObject) => {
          return !state.categoriesIntegrationList.some((existingCategory) => {
            return existingCategory.ID === newCategoryObject.ID
          })
        })
        state.categoriesIntegrationList.push(...uniqueCategories)
      } else state.categoriesIntegrationList = payload.data ?? payload
    },
    SET_NO_MORE_CATEGORIES(state, payload) {
      state.noMoreCategories = payload
    },
    CLEAR_CATEGORIES_INTEGRATION_LIST(state) {
      state.categoriesIntegrationList = []
    },
  },
  actions: {
    async getCategoriesIntegrationAPI({ commit, rootState }, q) {
      try {
        let query = { ...q }
        query.integracao =
          rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA
        let response = await service.getCategoriesIntegration(query)
        response.forward = false
        if (response.last_page == query.page) {
          commit('SET_NO_MORE_CATEGORIES', true)
          response.forward = true
        }
        commit('SET_CATEGORIES_INTEGRATION_LIST', response)
      } finally {
      }
    },

    clearCategoriesIntegrationList({ commit }) {
      commit('CLEAR_CATEGORIES_INTEGRATION_LIST')
    },

    setNoMoreCategories({ commit }, payload) {
      commit('SET_NO_MORE_CATEGORIES', payload)
    },

    async getCategoryIntegrationAPI({ rootState }, id) {
      try {
        let query = {}
        query.integracao =
          rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA
        let data = await service.getCategoryIntegrationById(id, query)
        return data
      } catch (error) {
        console.error(error)
      }
    },
    async insertCategoriesIntegration({ rootState }, payload) {
      const response = await service.insertCategoriesIntegration({
        data: payload,
        integracao: rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA,
      })
      return response
    },
    async deleteCategoryIntegration({ dispatch, state }, id) {
      const status = await service.delete(id)
      dispatch('getCategoriesIntegrationAPI', {})
      return status
    },
  },
  getters: {
    noMoreCategories: (state) => state.noMoreCategories,
  },
}
