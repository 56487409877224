import service from '@/services/integration/IntegrationService'
import countries from '@/mocks/countries'

let mCommerceInfo = localStorage.getItem('mCommerceInfo')
  ? JSON.parse(localStorage.getItem('mCommerceInfo'))
  : null

const defaultList = [
  {
    ATIVO: 'N',
    IMAGEM: 'mcommerce_h.svg',
    NOME_EMPRESA: 'MCommerce',
    DESCRICAO:
      'Integração base para o funcionamento do E-commerce, com ela será possivel definir os itens que serão utilizados no e-commerce, bem como adicionar novos serviços e formas de pagamento para o mesmo.',
    TIPOINTEGRACAO: 'servico',
    PAIS: 'ANY',
    DEFAULTIMG: true,
  },
  {
    ATIVO: 'N',
    IMAGEM: 'frenet.png',
    NOME_EMPRESA: 'Frenet',
    DESCRICAO:
      'A integração com a Frenet conecta transportadoras à sua loja virtual e ajuda você a oferecer mais opções de entrega aos seus clientes.',
    TIPOINTEGRACAO: 'frete',
    PAIS: 'BR',
    DEFAULTIMG: true,
  },

  {
    ATIVO: 'N',
    IMAGEM: 'logo_banco_brasil.png',
    NOME_EMPRESA: 'Pix (BB)',
    DESCRICAO: 'Integração de pagamento via Pix com o Banco do Brasil.',
    TIPOINTEGRACAO: 'pagamento',
    PAIS: 'BR',
    DEFAULTIMG: true,
  },
  {
    ATIVO: 'N',
    IMAGEM: 'logo_sicredi.png',
    NOME_EMPRESA: 'Pix (SC)',
    DESCRICAO: 'Integração de pagamento via pix com o Banco Sicredi.',
    TIPOINTEGRACAO: 'pagamento',
    PAIS: 'BR',
    DEFAULTIMG: true,
  },
  {
    ATIVO: 'N',
    IMAGEM: 'logo_asaas.png',
    NOME_EMPRESA: 'Asaas',
    PAIS: 'BR',
    DESCRICAO: 'Integração de pagamento via Asaas.',
    TIPOINTEGRACAO: 'pagamento',
    DEFAULTIMG: true,
  },
  // {
  //   ATIVO: 'N',
  //   IMAGEM: 'rendimento_pay_favicon2.webp',
  //   NOME_EMPRESA: 'Rendimento Pay',
  //   DESCRICAO: 'Integração de pagamento via Rendimento Pay.',
  //   TIPOINTEGRACAO: 'pagamento',
  //   DEFAULTIMG: true,
  // },
  {
    ATIVO: 'N',
    IMAGEM: 'pagopar.png',
    NOME_EMPRESA: 'Pagopar',
    DESCRICAO:
      'Integração de pagamento via Pagopar (exclusivo para empresas do Paraguai).',
    TIPOINTEGRACAO: 'pagamento',
    PAIS: 'PY',
    DEFAULTIMG: true,
  },
]
export const integrationModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    integration: {},
    mCommerceInfo: mCommerceInfo,
    selectedIntegrationInList: null,
    coresEcommerce: null,
    loadRequest: null,
    integrationsList: defaultList,
    loadingList: false,
    usingFreightIntegration: false,
  },

  mutations: {
    CLEAR_MCOMMERCE_INFO(state) {
      state.mCommerceInfo = null
      localStorage.removeItem('mCommerceInfo')
    },

    SET_LOADING_LIST(state, payload) {
      state.loadingList = payload
    },

    SET_INTEGRATIONS_LIST(state, payload) {
      if (payload.length < 1) {
        payload = defaultList.map((e) => {
          let obj = { ...e }
          return obj
        })
      } else {
        payload = defaultList.map((e) => {
          let obj = { ...e }
          const exist = payload.filter(
            (p) => p.NOME_EMPRESA == obj.NOME_EMPRESA
          )[0]
          if (exist) {
            if (exist.IMAGEM == null) {
              exist.IMAGEM = defaultList.filter(
                (i) => i.NOME_EMPRESA == exist.NOME_EMPRESA
              )[0].IMAGEM
              exist.DEFAULTIMG = true
            } else {
              exist.DEFAULTIMG = false
            }
            exist.DESCRICAO = obj.DESCRICAO
            exist.PAIS = obj.PAIS
            obj = { ...exist }
          }
          return obj
        })
      }

      state.integrationsList = payload
    },

    SET_SELECTED_INTEGRATION(state, payload) {
      const exist = state.integrationsList.filter(
        (p) => p.NOME_EMPRESA == payload.NOME_EMPRESA
      )[0]
      if (exist) {
        if (payload.IMAGEM == null) {
          payload.IMAGEM = exist.IMAGEM
          payload.DEFAULTIMG = true
        }
      }
      if (payload.NOME_EMPRESA != 'MCommerce') state.integration = payload
      else {
        state.mCommerceInfo = payload
        const formatedPayload = {
          NOME_EMPRESA: payload.NOME_EMPRESA,
          ATIVO: payload.ATIVO,
          ID: payload.ID,
          FILIAL: payload.FILIAL,
          IMAGEM: payload.IMAGEM,
        }
        localStorage.setItem('mCommerceInfo', JSON.stringify(formatedPayload))
      }
    },

    SET_NEW_IMAGE_FOR_INTEGRATION(state, payload) {
      const idx = state.integrationsList.findIndex(
        (e) => e.NOME_EMPRESA == payload.NOME_EMPRESA
      )
      if (idx > -1) {
        state.integrationsList[idx].IMAGEM = payload.IMAGEM
        state.integrationsList[idx].DEFAULTIMG = false
      }
    },

    SET_INTEGRATION_ACTIVE(state, payload) {
      const idx = state.integrationsList.findIndex(
        (e) => e.NOME_EMPRESA == payload
      )
      if (idx > -1) state.integrationsList[idx].ATIVO = 'S'
    },

    SET_INTEGRATION_AREA_ATUACAO_FRETE(state, payload) {
      state.integration.AREAATUACAOFRETE = payload
    },

    SET_USING_FREIGHT_INTEGRATION(state, payload) {
      state.usingFreightIntegration = payload
    },
  },
  actions: {
    setLoadingList({ commit }, payload) {
      commit('SET_LOADING_LIST', payload)
    },

    async getIntegrations({ commit }) {
      try {
        const { data } = await service.getIntegrations({ admin: true })
        commit('SET_INTEGRATIONS_LIST', data)
      } catch (error) {
        console.error(error)
      }
    },

    async checkFreights({ commit }) {
      const { data } = await service.checkFreights()
      commit('SET_USING_FREIGHT_INTEGRATION', data)
      return data
    },

    setNewImageForIntegration({ commit }, payload) {
      try {
        commit('SET_NEW_IMAGE_FOR_INTEGRATION', payload)
      } catch {
        console.error(error)
      }
    },

    async getIntegration({ commit, dispatch }, payload) {
      try {
        const response = await service.getIntegration(payload)
        commit('SET_SELECTED_INTEGRATION', response)
        return response
      } catch (error) {
        if (payload != 'MCommerce') {
          dispatch('adminModule/clearSelectedIntegration', null, {
            // caso a integração não exista mais, remove a seleção.
            root: true,
          })
        } else {
          commit('CLEAR_MCOMMERCE_INFO')
        }
        throw error
      }
    },

    async setSelectedIntegration({ commit }, payload) {
      try {
        commit('SET_SELECTED_INTEGRATION', payload)
      } catch (error) {
        console.error(error)
      }
    },

    async verifyIfExist({ commit }, payload) {
      try {
        const { data } = await service.verifyIntegration(payload)
        if (data) {
          await commit('SET_INTEGRATION_ACTIVE', payload)
        }
        return data
      } catch (error) {
        console.error(error)
      }
    },

    getBaseImageFromIntegration({ state }, payload) {
      try {
        const data = state.integrationsList.filter(
          (e) => e.NOME_EMPRESA == payload
        )[0]

        return data
      } catch (error) {
        console.error(error)
      }
    },

    async updateIntegration({}, payload) {
      try {
        return await service.updateIntegration(payload)
      } catch (error) {
        return error
      }
    },

    async patchAreaAtuacaoFrete({ commit }, payload) {
      try {
        const data = await service.patchAreaAtuacaoFrete(payload)
        await commit('SET_INTEGRATION_AREAATUACAOFRETE', payload.atuacao_frete)
        return data
      } catch (error) {
        console.error(error)
      }
    },

    async saveIntegration({ dispatch }, payload) {
      try {
        const response = await service.saveIntegration(payload)
        if (response.data.NOME_EMPRESA == 'MCommerce') {
          dispatch('systemModule/setSystemEcommerceTrue', true, {
            // caso a integração não exista mais, remove a seleção.
            root: true,
          })
        }
        return response
      } catch (error) {
        return error
      }
    },

    async getColors({ state }, payload) {
      try {
        const query = { filial: payload.filial }
        return await service.getColors(payload, query)
      } catch (error) {
        console.error(error)
      }
    },

    async getAreaAtuacaoFrete({ commit }, payload) {
      try {
        const { AREAATUACAOFRETE } = await service.getAreaAtuacaoFrete(payload)
        commit('SET_INTEGRATION_AREA_ATUACAO_FRETE', AREAATUACAOFRETE)
      } catch (error) {
        console.error(error)
      }
    },
  },
  getters: {
    hasAnyFreightIntegration(state) {
      return state.usingFreightIntegration
    },

    listaCbIntegrations(state) {
      if (state.integrationsList.length < 1) return
      let array = state.integrationsList.map((int) => {
        return {
          FILIAL: int.FILIAL,
          DESCRICAO: int.FILIAL + ' - ' + int.NOME_EMPRESA,
          NOME_EMPRESA: int.NOME_EMPRESA,
          VALUE: int.FILIAL + '_' + int.NOME_EMPRESA,
        }
      })
      return array
    },

    hasFrenet(state, getters, rootState) {
      let frenet = state.integrationsList?.filter(
        (e) => e.NOME_EMPRESA == 'Frenet'
      )[0].ATIVO

      if (frenet === 'N') {
        let integration = rootState.systemModule.system?.integracao?.filter(
          (v) => v.NOME_EMPRESA == 'Frenet'
        )[0]

        if (integration) frenet = integration.ATIVO
      }

      return frenet === 'S'
    },
    hasFreteGestao(state, getters, rootState) {
      return rootState.systemModule.system.FRETEGESTAO > 0
    },

    getIntegrationEcommerce(state, getters, rootState) {
      return rootState.systemModule.system.integracao.filter(
        (v) => v.NOME_EMPRESA == 'MCommerce'
      )[0]
    },

    selectedIntegration(state) {
      return state.integration
    },

    getCifraoFromSelectedIntegration(state) {
      return state.mCommerceInfo.CIFRAO
    },

    getMoedaFromSelectedIntegration(state) {
      return state.mCommerceInfo.MOEDA
    },

    getDefaultColors(state) {
      return JSON.parse(state.mCommerceInfo.CORES)
    },

    loadingList(state) {
      return state.loadingList
    },

    mCommerceInfo(state) {
      return state.mCommerceInfo
    },

    countries: (state, getters, rootState, rootGetters) => {
      const system = rootGetters['systemModule/getSystem']

      //Retorna todos países do mock e assinala o país principal como país origem do e-commerce
      return countries
        .map((country) => {
          country.isPrincipal = country.siglaPais === system?.SIGLAPAIS
          return country
        })
        .sort((a, b) => {
          return b.isPrincipal - a.isPrincipal
        })
    },

    countriesWeDeliverTo: (state, getters, rootState, rootGetters) => {
      if (!state.integration.AREAATUACAOFRETE) {
        const system = rootGetters['systemModule/getSystem']

        //Retorna apenas o país de origem do e-commerce caso não haja área de atuação de frete
        return getters.countries.filter(
          (country) => country.siglaPais === system?.SIGLAPAIS
        )
      }

      const areaAtuacaoFrete = state.integration.AREAATUACAOFRETE.split(',')
      // Caso haja área de atuação de frete, retorna um array de objetos baseados no mock do countries

      return countries.filter((country) =>
        areaAtuacaoFrete.includes(country.siglaPais)
      )
    },
  },
}
