import Service from '../Service'
class ItemIntegrationService extends Service {
  constructor() {
    super()
    this.resource = 'mbcommerce/items-integration'
  }

  async getItems(query = null) {
    let requestUrl = this.createRequestUrl(query, this.resource)
    return await this.getAPI(requestUrl)
  }

  async destroyArrayofItens(payload, query) {
    let requestUrl = `/${this.resource}/destroyArrayofItens/${query}`
    return await this._http.delete(requestUrl, { data: { itens: payload } })
  }

  async getItem(payload, integracao = null) {
    let requestUrl = `/${this.resource}/${payload}`
    return await this.getAPI(requestUrl)
  }

  async insertItemIntegration(payload, integracao = null) {
    let requestUrl = `/${this.resource}`
    if (integracao) requestUrl += `?integracao=${integracao}`
    return await this._http.post(requestUrl, payload)
  }

  async deleteItemIntegration(payload, query) {
    let requestUrl = `/${this.resource}/${payload}`
    requestUrl = this.createRequestUrl(query, requestUrl)
    return await this._http.delete(requestUrl)
  }

  async getItemsIntegration(query, integracao = null) {
    const resource = `/${this.resource}`
    let requestUrl = this.createRequestUrl(query, resource)
    if (integracao) requestUrl += `?integracao=${integracao}`
    return await this.getAPI(requestUrl)
  }
}

export default new ItemIntegrationService()
