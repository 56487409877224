import service from '@/services/category/CategoryService'

const categories = localStorage.getItem('selectedCategories')
let selectedCategories = categories ? JSON.parse(categories) : []
export const categoryModule = {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    categoriesList: [],
    selectedCategories,
  },
  mutations: {
    SET_CATEGORIES_LIST(state, payload) {
      state.categoriesList = payload.data ?? payload
    },

    CHANGE_INTEGRATED_IN_CATEGORIES_LIST(state, payload) {
      state.categoriesList = state.categoriesList.map((e) => {
        let obj = { ...e }
        if (payload.includes(e.ID)) {
          obj.INTEGRADO = 'S'
        }
        return obj
      })
    },

    SET_SELECTED_CATEGORIES(state, payload) {
      payload = payload.data ?? payload
      state.selectedCategories = payload.map((e) => {
        let obj = { ...e }
        const exist = state.selectedCategories.find((item) => item.ID == e.ID)
        if (exist && exist.SAVED != undefined) {
          exist.ID = obj.ID
          return exist
        }
        return obj
      })
      localStorage.setItem(
        'selectedCategories',
        JSON.stringify(state.selectedCategories)
      )
    },
    SET_ITEM_IN_SELECTED_CATEGORIES(state, payload) {
      const index = state.selectedCategories.findIndex(
        (e) => e.ID == payload.ID
      )
      const id = state.selectedCategories[index].ID
      state.selectedCategories[index] = { ...payload }
      state.selectedCategories[index].ID = id
      state.selectedCategories[index].SAVED = true
      localStorage.setItem(
        'selectedCategories',
        JSON.stringify(state.selectedCategories)
      )
    },

    EDIT_ALL_IN_SELECTED_CATEGORIES(state, payload) {
      state.selectedCategories = state.selectedCategories.map((e) => {
        let obj = { ...e }
        if (obj.ID != payload.PARENT_ID) {
          obj.PARENT_ID = payload.PARENT_ID
        }
        obj.SAVED = true
        obj.SUBTITULO = payload.SUBTITULO
        return obj
      })
      localStorage.setItem(
        'selectedCategories',
        JSON.stringify(state.selectedCategories)
      )
    },

    REMOVE_SELECTED_CATEGORY(state, id) {
      const idx = state.selectedCategories.findIndex((e) => e == id)
      state.selectedCategories.splice(idx, 1)

      localStorage.setItem(
        'selectedCategories',
        JSON.stringify(state.selectedCategories)
      )
    },
  },
  actions: {
    async getCategoriesAPI({ commit, rootState }, q) {
      let query = { ...q }
      query.integracao = rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA
      query.filial = rootState.adminModule.selectedFilial
      query.TODOS = 'S'
      let response = await service.getCategories(query)
      commit('SET_CATEGORIES_LIST', response)
    },
    getItemInSelectedCategories({ state }, payload) {
      try {
        return state.selectedCategories.find((e) => e.ID == payload)
      } catch (e) {
        throw e
      }
    },
    setItemInSelectedCategories({ commit }, payload) {
      try {
        commit('SET_ITEM_IN_SELECTED_CATEGORIES', payload)
      } catch (e) {
        throw e
      }
    },
    editAllInSelectedCategories({ commit }, payload) {
      try {
        commit('EDIT_ALL_IN_SELECTED_CATEGORIES', payload)
      } catch (e) {
        throw e
      }
    },

    changeIntegratedInCategoriesList({ commit }, payload) {
      try {
        commit('CHANGE_INTEGRATED_IN_CATEGORIES_LIST', payload)
      } catch (e) {
        throw e
      }
    },
    async getCategoryAPI({ rootState }, id) {
      let query = {}
      query.integracao = rootState.integrationModule.mCommerceInfo?.NOME_EMPRESA
      let data = await service.getCategory(query, id)
      return data
    },
    async getSelectedCategories({ rootState, state }, payload) {
      const query = {
        integracao: rootState.integrationModule.mCommerceInfo.NOME_EMPRESA,
      }
      const data = await service.getSelectedCategorys({ data: payload }, query)
      return data.map((e) => {
        let obj = { ...e }
        const saved = state.selectedCategories.find((item) => item.ID == e.ID)
        if (saved) {
          saved.INTEGRADO = obj.INTEGRADO
          return saved
        }
        return obj
      })
    },

    getFullListOfSelectedCategories({ state }) {
      return state.selectedCategories
    },

    async setSelectedCategories({ commit }, payload) {
      commit('SET_SELECTED_CATEGORIES', payload)
    },
    async removeSelectedCategory({ commit }, payload) {
      commit('REMOVE_SELECTED_CATEGORY', payload)
    },
  },
  getters: {
    getSelectedCategories: (state) => {
      return state.selectedCategories.map((e) => {
        return e.ID
      })
    },
  },
}
